export const publicationData = [
    {
        year: 2024,
        articles: [
            {
                clickable: false,
                articleName: `
* #### Human Preference-aware Rebalancing and Charging for Shared Electric Micromobility Vehicles 

    Heng Tan, Yukun Yuan, Hua Yan, Shuxin Zhong, Yu Yang.
    
    *IEEE International Conference on Robotics and Automation (ICRA), 2024*
`,
            },
            {
                clickable: false,
                articleName: `
* #### Robust Route Planning under Uncertain Pickup Requests for Last-mile Delivery 

    Hua Yan, Heng Tan, Haotian Wang, Desheng Zhang, Yu Yang.
    
    *The Web Conference (WWW), 2024*
`,
            },
        ],
    },
    {
        year: 2023,
        articles: [
            {
                clickable: false,
                articleName: `
* #### RLIFE: Remaining Lifespan Prediction for E-scooters 

    Shuxin Zhong, William Yubeaton, Wenjun Lyu, Guang Wang, Desheng Zhang, and Yu Yang.
    
    *In the 32st ACM International Conference on Information and Knowledge Management (CIKM), 2023*
`,
            },
            {
                clickable: false,
                articleName: `
* #### CARPG: Cross-City Knowledge Transfer for Traffic Accident Prediction via Attentive Region-level Parameter Generation 

    Guang Yang, Yuequn Zhang, Jinquan Hang, Xinyue Feng, Zejun Xie, Desheng Zhang, and Yu Yang.
    
    *In the 32st ACM International Conference on Information and Knowledge Management (CIKM), 2023*
`,
            },
            {
                clickable: false,
                articleName: `
* #### Identifying Regional Driving Risks via Transductive Cross-City Transfer Learning Under Negative Transfer 

    Hua Yan, Hao Wang, Desheng Zhang, and Yu Yang.
    
    *In the 32st ACM International Conference on Information and Knowledge Management (CIKM), 2023*
`,
            },
            {
                clickable: false,
                articleName: `
* #### Data-Driven Distributionally Robust Electric Vehicle Balancing for Autonomous Mobility-on-Demand Systems under Demand and Supply Uncertainties

    Sihong He, Zhili Zhang, Shuo Han, Lynn Pepin, Guang Wang, Desheng Zhang, John Stankovic, and Fei Miao.
    
    *In IEEE Transactions on Intelligent Transportation Systems, 2023*
`,
            },
            {
                clickable: false,
                articleName: `
* #### Robust Multi-Agent Reinforcement Learning with Adversarial State Uncertainties 

    Sihong He, Songyang Han, Sanbao Su, Shuo Han, Shaofeng Zou, and Fei Miao.
    
    *In Transactions on Machine Learning Research, 2023*
`,
            },
            {
                clickable: false,
                articleName: `
* #### Robust Electric Vehicle Balancing of Autonomous Mobility-on-Demand System: A Multi-Agent Reinforcement Learning Approach 

    Sihong He, Shuo Han, and Fei Miao.
    
    *accepted, In Proceedings of the IEEE/RSJ International Conference on Intelligent Robots and Systems (IROS), 2023*
`,
            },
            {
                clickable: false,
                articleName: `
* #### A Robust and Constrained Multi-Agent Reinforcement Learning Electric Vehicle Rebalancing Method in AMoD Systems 

    Sihong He, Yue Wang, Shuo Han, Shaofeng Zou, and Fei Miao.
    
    *accepted, Proceedings of the IEEE/RSJ International Conference on Intelligent Robots and Systems (IROS), 2023*
`,
            },
            {
                clickable: false,
                articleName: `
* #### An STL-based Approach to Resilient Control for Cyber-Physical Systems 

    Hongkai Chen, Scott A. Smolka, Nicola Paoletti, and Shan Lin.
    
    *In the 26th ACM International Conference on Hybrid Systems: Computation and Control (HSCC), 2023*
`,
            },
            {
                clickable: false,
                articleName: `
* #### ForETaxi: Data-Driven Fleet-Oriented Charging Resource Allocation in Large-Scale Electric Taxi Networks

    Guang Wang, Yuefei Chen, Shuai Wang, Fan Zhang, Desheng Zhang.
    
    *ACM Transactions on Sensor Networks, 2023*
`,
            },
            {
                clickable: false,
                articleName: `
* #### Data-Driven Distributionally Robust Electric Vehicle Balancing for Autonomous Mobility-on-Demand Systems Under Demand and Supply Uncertainties 

    Sihong He, Zhili Zhang, Shuo Han, Lynn Pepin, Guang Wang, Desheng Zhang, John A. Stankovic, Fei Miao.
    
    *IEEE Transactions on Intelligent Transportation Systems, 2023*
`,
            },
            {
                clickable: false,
                articleName: `
* #### Social equity in the data era: A systematic literature review of data‐driven public service research 

    Ruijer, E., Porumbescu, G., Porter, R., & Piotrowski, S.
    
    *Public Administration Review, 83(2), 316-332, 2023*
`,
            },
        ],
    },
    {
        year: 2022,
        articles: [
            {
                clickable: false,
                articleName: `
* #### Stable and Efficient Shapley Value-Based Reward Reallocation for Multi-Agent Reinforcement Learning of Autonomous Vehicles 

    Songyang Han, He Wang, Sanbao Su, Yuanyuan Shi, and Fei Miao.
    
    *In Proceedings of IEEE International Conference on Robotics and Automation (ICRA), 2022*
`,
            },
            {
                clickable: false,
                articleName: `
* #### Game Theoretic Analysis of Urban E-Taxi Systems: Equilibria and Efficiency 

    Yukun Yuan, Yue Zhao, Lin Chen, and Shan Lin.
    
    *In the 19th Annual IEEE International Conference on Sensing, Communication, and Networking (SECON), 2022*
`,
            },
            {
                clickable: false,
                articleName: `
* #### POET: Towards Power-System-Aware E-Taxi Coordination under Dynamic Passenger Mobility 

    Yukun Yuan, Yue Zhao, and Shan Lin.
    
    *In Thirteenth ACM International Conference on Future Energy Systems (ACM e-Energy), 2022*
`,
            },
            {
                clickable: false,
                articleName: `
* #### DeResolver: A Decentralized Conflict Resolution Framework with Autonomous Negotiation for Smart City Services 

    Yukun Yuan, Meiyi Ma, Songyang Han, Desheng Zhang, Fei Miao, John A. Stankovic, Shan Lin.
    
    *ACM Transactions on Cyber-Physical Systems, 2022*
`,
            },
            {
                clickable: false,
                articleName: `
* #### Towards Accessible Shared Autonomous Electric Mobility with Dynamic Deadlines 

    Guang Wang, Zhou Qin, Shuai Wang, Huijun Sun, Zheng Dong, Desheng Zhang.
    
    *IEEE Transactions on Mobile Computing (TMC), 2022*
`,
            }, 
            {
                clickable: false,
                articleName: `
* #### Softening Performance's Pitfalls by Integrating Context and Capacity: A Government Competitiveness Framework 

    Christensen, Robert, Im, Tobin, & Porumbescu, Gregory.
    
    *Public Administration Review, 82(5), 887-892, 2022*
`,
            },
            {
                clickable: false,
                articleName: `
* #### Government Transparency: State of the Art and New Perspectives 

    Porumbescu, G., Meijer, A., & Grimmelikhuijsen, S.
    
    *Cambridge University Press, 2022*
`,
            },
            {
                clickable: false,
                articleName: `
* #### Introduction to the special issue on Inclusion and E-Government: Progress and Questions for Scholars of Social Equity 

    Erna Ruijer, and Suzanne Piotrowski.
    
    *Information Polity 27, 4, 425–432, 2022*
`,
            },                
            {
                clickable: false,
                articleName: `
* #### Resident motivations for requesting services through Open311: A case study of Newark Connect 

    Trehan, Vishal; Porumbescu, Gregory; Liu, Ying; Harrison, Alzira; Piotrowski, Suzanne.
    

`,
            },
            {
                clickable: false,
                articleName: `
* #### P2-Loc: A Person-2-Person Indoor Localization System in On-Demand Delivery 

    Yi Ding, Dongzhe Jiang, Yu Yang, Yunhuai Liu, Tian He, Desheng Zhang.
    
    *The Proceedings of the ACM on Interactive, Mobile, Wearable and Ubiquitous Technologies (IMWUT), 2022*
`,
            },
            {
                clickable: false,
                articleName: `
* #### WePos: Weak-supervised Indoor Positioning with Unlabeled WiFi for On-demand Delivery 

    Baoshen Guo, Weijian Zuo, Shuai Wang, Wenjun Lyu, Zhiqing Hong, Yi Ding, Tian He, Desheng Zhang.
    
    *The Proceedings of the ACM on Interactive, Mobile, Wearable and Ubiquitous Technologies (IMWUT), 2022*
`,
            },
            {
                clickable: false,
                articleName: `
* #### TransRisk: Mobility Privacy Risk Prediction based on Transferred Knowledge 

    Xiaoyang Xie, Zhiqing Hong, Zhou Qin, Zhihan Fang, Yuan Tian, Desheng Zhang.
    
    *The Proceedings of the ACM on Interactive, Mobile, Wearable and Ubiquitous Technologies (IMWUT), 2022*
`,
            },
            {
                clickable: false,
                articleName: `
* #### VeMo: Enable Transparent Vehicular Mobility Modeling at Individual Levels With Full Penetration 

    Yu Yang, Xiaoyang Xie, Zhihan Fang, Fan Zhang, Yang Wang, Desheng Zhang.
    
    *IEEE Transactions on Mobile Computing, 2022*
`,
            },
            {
                clickable: false,
                articleName: `
* #### Towards Fair Workload Assessment via Homogeneous Order Grouping in Last-mile Delivery 

    Wenjun Lyu, Kexin Zhang, Baoshen Guo, Zhiqing Hong, Guang Yang, Guang Wang, Yu Yang, Yunhuai Liu, Desheng Zhang.
    
    *The Conference on Information and Knowledge Management (CIKM), 2022*
`,
            },
            {
                clickable: false,
                articleName: `
* #### BikeCAP: Deep Spatial-temporal Capsule Network for Multi-step Bike Demand Prediction 

    Shuxin Zhong, Wenjun Lyu, Desheng Zhang, Yu Yang.
    
    *IEEE International Conference on Distributed Computing Systems (ICDCS), 2022*
`,
            },
            {
                clickable: false,
                articleName: `
* #### <var>O</var><sup>2</sup>-SiteRec: Store Site Recommendation under the O2O Model via Multi-graph Attention Networks 

    Hua Yan, Shuai Wang, Yu Yang, Baoshen Guo, Tian He, Desheng Zhang.
    
    *IEEE International Conference on Data Engineering (ICDE), 2022*
`,
            },
            {
                clickable: false,
                articleName: `
* #### Para-Pred: Addressing Heterogeneity for City-Wide Indoor Status Estimation in On-Demand Delivery 

    Wei Liu, Yi Ding, Shuai Wang, Yu Yang, Desheng Zhang.
    
    *SIGKDD Conference on Knowledge Discovery and Data Mining, 2022*
`,
            },
            {
                clickable: false,
                articleName: `
* #### CitySpec: An Intelligent Assistant System for Requirement Specification in Smart Cities

    Zirong Chen, Issac Li, Haoxiang Zhang, Sarah Preum, John Stankovic, Meiyi Ma.
    
    *IEEE International Conference on Smart Computing (SMARTCOMP), 2022*
`,
            },
            {
                clickable: false,
                articleName: `
* #### Demo: An Intelligent Assistant for Converting City Requirements to Formal Specification

    Zirong Chen, Issac Li, Haoxiang Zhang, Sarah Preum, John Stankovic, Meiyi Ma.
    
    *IEEE International Conference on Smart Computing (SMARTCOMP), 2022*
`,
            },
            {
                clickable: false,
                articleName: `
* #### Stable and Efficient Reward Reallocation for Cooperative Policy Learning of Connected Autonomous Vehicles

    Songyang Han, He Wang, Sanbao Su, Yuanyuan Shi, and Fei Miao.
    
    *IEEE International Conference on Robotics and Automation (ICRA), 2022*
`,
            },
            {
                clickable: false,
                articleName: `
* #### An STL-Based Formulation of Resilience in Cyber-Physical Systems

    Hongkai Chen, Shan Lin, Scott A. Smolka, Nicola Paoletti.
    
    *International Conference on Formal Modeling and Analysis of Timed Systems (FORMATS), 2022*
`,
            },
            {
                clickable: false,
                articleName: `
* #### RFCam: Uncertainty-aware Fusion of Camera and Wi-Fi for Real-time Human Identification with Mobile Devices

    Hongkai Chen, Sirajum Munir, Shan Lin.
    
    *The Proceedings of the ACM on Interactive, Mobile, Wearable and Ubiquitous Technologies (IMWUT), 2022*
`,
            },
            {
                clickable: false,
                articleName: `
* #### Charging Path Optimization in Mobile Networks

    Lin Chen, Shan Lin, Hua Huang, Weihua Yang.
    
    *IEEE/ACM Transactions on Networking, 2022*
`,
            },
            {
                clickable: false,
                articleName: `
* #### Whose open data is it anyway? An exploratory study of open government data relevance and implications for democratic inclusion

    Schwoerer, Kayla.
    
    *Information Polity, 2022*
`,
            },
            {
                clickable: false,
                articleName: `
* #### When blame avoidance backfires: Responses to performance framing and outgroup scapegoating during the COVID-19 pandemic

    Gregory Porumbescu, Donald Moynihan, Jason Anastasopoulos, Asmus Leth Olsen.
    
    *Governance, 2022*
`,
            },


        ],
    },

    {
        year: 2021,
        articles: [

            {
                clickable: false,
                articleName: `
* #### Joint Real-Time Repositioning and Charging for Electric Carsharing with Dynamic Deadlines

    Guang Wang, Zhou Qin, Shuai Wang, Huijun Sun, Zheng Dong, Desheng Zhang.
    
    *SIGKDD Conference on Knowledge Discovery and Data Mining, 2021*
`,
            },

            {
                clickable: false,
                articleName: `
* #### Data-Driven Fairness-Aware Vehicle Displacement for Large-Scale Electric Taxi Fleets

    Guang Wang, Shuxin Zhong, Shuai Wang, Fei Miao, Zheng Dong, Desheng Zhang.
    
    *IEEE International Conference on Data Engineering (ICDE), 2021*
`,
            },

            {
                clickable: false,
                articleName: `
* #### eRoute: Mobility-Driven Integration of Heterogeneous Urban Cyber-Physical Systems under Disruptive Events

    Yukun Yuan, Desheng Zhang, Fei Miao, John A. Stankovic, Tian He, George Pappas, Shan Lin.
    
    *IEEE Transactions on Mobile Computing, 2021*
`,
            },
            {
                clickable: false,
                articleName: `
* #### Predictive Monitoring with Logic-Calibrated Uncertainty for Cyber-Physical Systems

    Meiyi Ma, John Stankovic, Ezio Bartocci and Lu Feng.
    
    *International Conference on Embedded Software (EMSOFT), 2021*
`,
            },
            {
                clickable: true,
                articleName:
`* #### DeResolver: A Decentralized Negotiation and Conflict Resolution Framework for Smart City Services
            
    Yukun Yuan, Meiyi Ma, Songyang Han, Desheng Zhang, Fei Miao, John A. Stankovic and Shan Lin.
    
    *International Conference on Cyber-Physical Systems (ICCPS), 2021*
`,
                buttonText: `Best Paper Award`,
                clickToShow:
`<img 
    src="https://raw.githubusercontent.com/biyunwu/smart-connected-newark/main/static/homepage_slideshow/slideshow2.webp" 
    alt="Best Paper Award" 
    style="width:100%; max-width: 600px;"
/>
`,
            },
            {
                clickable: false,
                articleName: `
* #### SAC: Solar-Aware E-Taxi Fleet Charging Coordination under Dynamic Passenger Mobility

    Yukun Yuan, Yue Zhao, Shan Lin.
    
    *IEEE Conference on Decision and Control (CDC), 2021*
`,
            },
            {
                clickable: false,
                articleName: `
* #### Performance Information, Racial Bias, and Citizen Evaluations of Government: Evidence from Two Studies

    Gregory A Porumbescu,  Suzanne J Piotrowski,  Vincent Mabillard.
    
    *Journal of Public Administration Research and Theory, 2021*
`,
            },
        ],
    },
    {
        year: 2018,
        articles: [
            {
                clickable: false,
                articleName: `* #### Cityresolver: A Decision Support System for Conflict Resolution in Smart Cities
            
    Meiyi Ma , John A Stankovic, Lu Feng
    
    *Proceedings of the 9th ACM/IEEE International Conference on Cyber-Physical Syste*
    
    [[Download](https://www.cs.virginia.edu/~stankovic/psfiles/CityResolver.pdf)] [[Link](https://dl.acm.org/citation.cfm?id=3207904)]
`,
            },
        ],
    },
    {
        year: 2017,
        articles:[
            {
                clickable: false,
                articleName:
`* #### CityGuard: A Watchdog for Safety-Aware Conflict Detection in Smart Cities

    Meiyi Ma, Sarah Masud Preum, John A Stankovic

    *2017 IEEE/ACM Second International Conference on Internet-of-Things Design and Implementation*

    [[Download](https://www.cs.virginia.edu/~stankovic/psfiles/SmartCityIOTDI.pdf)] [[Link](https://dl.acm.org/doi/10.1145/3054977.3054989)]
`,
            },
        ],
    },
]